import { makeAutoObservable } from 'mobx';
import { ILangs, ILangsObjs } from '../dto/translate';
import { languages } from 'static/languages';
import { TextStore } from './TextStore';
import { VoiceStore } from './VoiceStore';

export class MainStore {
  fromLang: ILangs = 'ru';

  toLang: ILangs = 'en';

  constructor(
    private textStore: TextStore,
    private voiceStore: VoiceStore,
  ) {
    makeAutoObservable(this);
  }

  getLangByKey = (key: ILangs) => {
    return languages.find((item) => item.lang === key) as ILangsObjs;
  };

  setLangByKey = (key: 'fromLang' | 'toLang', value: ILangs, currentRoute?: string) => {
    this[key] = value;
    console.log('Changing lang', currentRoute);
    if (currentRoute === 'text') {
      // TODO: seems that we are loosing workId for matching feedback in that case
      this.textStore.translate({
        from: this.fromLang,
        to: this.toLang,
        text: this.textStore.value,
        workId: crypto.randomUUID(),
      });
    }
  };

  switchLang = () => {
    const lastFrom = this.fromLang;
    const lastTo = this.toLang;

    // TODO: 1. seems that we are loosing workId for matching feedback in that case
    // TODO: remove swap logic since it produces bugs such as:
    // en -> ru: привет : привет (because en model won't translate ru text)
    // switchLang() => привет : привет (we expect привет : hello)
    // and generally it's a good practice to retranslate after switch
    this.toLang = lastFrom;
    this.fromLang = lastTo;

    this.textStore.switchLang();
  };

  get fromLangObj() {
    return this.getLangByKey(this.fromLang);
  }

  get toLangObj() {
    return this.getLangByKey(this.toLang);
  }
}
