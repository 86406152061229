import { makeAutoObservable } from 'mobx';
import TranslateService from '../service/translateService';

export class VoiceStore {
  value: string = '';

  translation: string = '';

  // TODO: place sessionId to mainStore?
  sessionId: string = crypto.randomUUID();

  currentWorkId: string = '';

  audio: string = '';

  constructor() {
    makeAutoObservable(this);
  }

  translateVoice = async (params: { from: string; to: string; speech: string }) => {
    this.currentWorkId = crypto.randomUUID();

    const { data: asrData } = await TranslateService.speechInput({
      mode: params.from,
      file: params.speech.replace('data:audio/wave;base64,', ''),
      session_id: this.sessionId,
      work_id: this.currentWorkId,
    });

    this.value = asrData.text;

    await this.translate({
      from: params.from,
      to: params.to,
      text: asrData.text,
      workId: this.currentWorkId,
      sessionId: this.sessionId,
    });

    await this.textToSpeech({ text: this.translation, mode: params.to });
  };

  updateVoiceTranslation = async (params: { from: string; to: string }) => {
    await this.translate({
      from: params.from,
      to: params.to,
      text: this.value,
      workId: this.currentWorkId,
      sessionId: this.sessionId,
    });

    await this.textToSpeech({ text: this.translation, mode: params.to });
  };

  textToSpeech = async (params: { text: string; mode: string }) => {
    if (params.text === undefined || params.text === '') {
      this.audio = '';
      return;
    }

    const { data: audioBase64 } = await TranslateService.speechOutput({
      mode: params.mode,
      text: this.translation, // TODO: remind Anton / do it myself: to finally refactor that copy&paste from asr to make request/response fields representetive
    });

    this.audio = audioBase64.file;
  };

  translate = async (params: { from: string; to: string; text: string; workId: string; sessionId?: string }) => {
    const { data: tranlatorData } = await TranslateService.translate({
      from: params.from,
      to: params.to,
      text: params.text,
      work_id: params.workId,
      session_id: params.sessionId,
    });

    this.translation = tranlatorData.translation;
  };
}
