import { createContext, useContext } from 'react';
import { MainStore } from 'adapters/store/MainStore';
import { TextStore } from 'adapters/store/TextStore';
import { DocsStore } from 'adapters/store/DocsStore';
import { VoiceStore } from 'adapters/store/VoiceStore';

const textStore = new TextStore();
const voiceStore: VoiceStore = new VoiceStore();
const mainStore = new MainStore(textStore, voiceStore);
const docsStore = new DocsStore();

const storeContext = createContext({
  mainStore,
  textStore,
  voiceStore,
  docsStore,
});

const useStores = () => useContext(storeContext);

export default useStores;
