import { makeAutoObservable } from 'mobx';
//import _debounce from 'lodash.debounce';
import TranslateService from '../service/translateService';
import { StatusType, OutputFileFormat } from '../../adapters/dto/translate';

export class DocsStore {
  history: string = '';

  mockProgress: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  translateDocFile = async (
    params: {
      from: string;
      to: string;
      file: File;
    },
    callbackProgressFunction: Function,
  ) => {
    if (null == params || null == params.file) return Promise.reject({ message: 'Передан пустой файл' });
    const formData = new FormData();
    formData.set('from_lang', params.from);
    formData.set('to_lang', params.to);
    formData.set('document', params.file);
    return TranslateService.sendDocToTranslate(formData, callbackProgressFunction).then((response) => {
      return {
        name: params.file.name,
        url: response.task_status_url,
      };
    });
  };

  translateVideoFile = async (
    params: {
      from: string;
      to: string;
      video: File;
      outputFormat: OutputFileFormat;
    },
    callbackProgressFunction: Function,
  ) => {
    if (null == params || null == params.video) return Promise.reject({ message: 'Передан пустой файл' });
    const outputFormat = params.outputFormat ?? OutputFileFormat.DOCX;
    const formData = new FormData();
    formData.set('from_lang', params.from);
    formData.set('to_lang', params.to);
    formData.set('output_format', outputFormat);
    formData.set('video', params.video);
    return TranslateService.sendVideoToTranslate(formData, callbackProgressFunction).then((response) => {
      if (response.data) {
        return {
          name: params.video.name + '.' + outputFormat,
          url: response.data.task_status_url,
        };
      } else {
        return Promise.reject({ message: 'Ошибка сервиса, полученый пустые данные' });
      }
    });
  };

  translateVideoLink = async (
    params: {
      from: string;
      to: string;
      link: string;
      outputFormat: OutputFileFormat;
    },
    callbackProgressFunction: Function,
  ) => {
    if (null == params || !params.link) return Promise.reject({ message: 'Передана пустая ссылка' });
    return TranslateService.validateVideoLink({ video_link: params.link }).then((result) => {
      const outputFormat = params.outputFormat ?? OutputFileFormat.DOCX;
      const formData = new FormData();
      formData.set('from_lang', params.from);
      formData.set('to_lang', params.to);
      formData.set('output_format', outputFormat);
      formData.set('video_link', params.link);
      return TranslateService.sendVideoToTranslate(formData, callbackProgressFunction).then((response) => {
        if (response.data) {
          return {
            name: 'transcript.' + outputFormat,
            url: response.data.task_status_url,
          };
        } else {
          return Promise.reject({ message: 'Ошибка сервиса, полученый пустые данные' });
        }
      });
    });
  };

  monitorTranslationStatus = (statusLink: string, callbackProgressFunction: Function) => {
    const _this = this;
    return new Promise((resolve, reject) => {
      const myInterval = setInterval(() => {
        _this
          .getDocumentStatus(statusLink)
          .then((status) => {
            if (status && status.status === StatusType.IN_PROGRESS) {
              callbackProgressFunction(status.progress);
            } else if (status && status.status === StatusType.COMPLETED) {
              clearInterval(myInterval);
              resolve(status.task_result_url);
            } else {
              console.log('unknown status');
              clearInterval(myInterval);
              reject({ message: 'Неизвестная ошибка с кодом ' + status?.status });
            }
          })
          .catch((err) => {
            console.log('error ' + err);
            clearInterval(myInterval);
            reject(err);
          });
      }, 1000);
    });
  };

  getDocumentStatus = async (link: string) => {
    const { data } = await TranslateService.getObjectStatus(link);
    if (!TranslateService.isStatusHealthy(data))
      return Promise.reject({
        message: 'Ошибка перевода файла' + (data.error_details ? '. Детали ошибки: ' + data.error_details.detail : ''),
      });
    return {
      status: data.status,
      progress: Math.round(data.progress * 100),
      task_result_url: data.task_result_url,
    };
  };

  downloadFile = (link: string, defaultFileName: string, callbackProgressFunction: Function) => {
    return TranslateService.download(link, defaultFileName, callbackProgressFunction);
  };
}
