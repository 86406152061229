import styles from './styles.module.scss';
import useStores from '../../adapters/store';
import { useAudioRecorder } from 'react-audio-voice-recorder';
import Button from '../../components/ui/button';
import AudioBlobView from '../../components/ui/audioBlob';
import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as MicIcon } from 'icons/normal_u17.svg';
import { getWaveBlob } from 'webm-to-wav-converter';
import cn from 'classnames';
import Language from 'components/layout/language';

const Voice = () => {
  const { startRecording, stopRecording, recordingBlob, isRecording } = useAudioRecorder();

  const { voiceStore, mainStore } = useStores();

  const [ttsAudioUrl, setTtsAudioUrl] = useState('');
  const [s2tAudioUrl, setS2tAudioUrl] = useState('');
  const [micAnimation, setMicAnimation] = useState(false);

  const getW = useCallback(
    async (recordingBlob: any) => {
      const rec = await getWaveBlob(recordingBlob, false);
      const s2tBlobUrl = URL.createObjectURL(rec);

      setS2tAudioUrl(s2tBlobUrl);

      var reader = new FileReader();
      reader.readAsDataURL(rec);

      reader.onloadend = function () {
        const speech = reader.result as string;

        voiceStore.translateVoice({ from: mainStore.fromLang, to: mainStore.toLang, speech }).then(() => {
          const arrayBuffer = Uint8Array.from(atob(voiceStore.audio), (c) => c.charCodeAt(0)).buffer;
          const blob = new Blob([arrayBuffer], { type: 'audio/wav' });
          const blobUrl = URL.createObjectURL(blob);
          setTtsAudioUrl(blobUrl);
        });
      };
    },
    [mainStore.fromLang, mainStore.toLang, voiceStore],
  );

  const resetAudio = useCallback(() => {
    setTtsAudioUrl('');
    setS2tAudioUrl('');
  }, []);

  const updateTts = useCallback(() => {
    // TODO: find a way to move from that hardcoded evens inced into Language component
    voiceStore.updateVoiceTranslation({ from: mainStore.fromLang, to: mainStore.toLang }).then(() => {
      const arrayBuffer = Uint8Array.from(atob(voiceStore.audio), (c) => c.charCodeAt(0)).buffer;
      const blob = new Blob([arrayBuffer], { type: 'audio/wav' });
      const blobUrl = URL.createObjectURL(blob);
      setTtsAudioUrl(blobUrl);
    });
  }, [mainStore.fromLang, mainStore.toLang, voiceStore]);

  useEffect(() => {
    if (voiceStore.audio === '') {
      resetAudio();
    }

    if (!recordingBlob) return;

    // recordingBlob will be present at this point after 'stopRecording' has been called
    getW(recordingBlob);
  }, [getW, recordingBlob, resetAudio, voiceStore]);

  const toggleRecord = useCallback(async () => {
    setMicAnimation(!micAnimation);

    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
      resetAudio();
    }
  }, [isRecording, micAnimation, resetAudio, startRecording, stopRecording]);

  return (
    <div>
      <div className={styles.lang}>
        <Language currentRoute="voice" onSwitchLang={resetAudio} onSelectChange={updateTts} />
      </div>

      <div className={styles.root}>
        <div className={styles.mircoZone}>
          <Button className={cn(styles.micButton, { [styles.enabled]: micAnimation })} onClick={toggleRecord}>
            <MicIcon className={cn(styles.micIcon, { [styles.enabled]: micAnimation })}></MicIcon>
          </Button>
        </div>
      </div>

      <div className={styles.audioArea}>
        <div className={styles.audioContainer}>
          {ttsAudioUrl !== '' && (
            <AudioBlobView
              audioUrl={s2tAudioUrl}
              header={mainStore.fromLangObj.name}
              transcritption={voiceStore.value}
            ></AudioBlobView>
          )}
        </div>

        <div className={styles.audioContainer}>
          {ttsAudioUrl !== '' && (
            <AudioBlobView
              audioUrl={ttsAudioUrl}
              header={mainStore.toLangObj.name}
              transcritption={voiceStore.translation}
            ></AudioBlobView>
          )}
        </div>
      </div>
    </div>
  );
};

export default Voice;
