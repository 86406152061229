import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { ReactComponent as Logo } from 'icons/logo.svg';
import { routes } from '../../static/navigation';
import cn from 'classnames';
import styles from './styles.module.scss';

const Layout = () => {
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Logo />
        <div className={styles.links}>
          {routes.map((navItem) => (
            <NavLink
              key={navItem.path}
              className={({ isActive }) => {
                return cn(styles.navLink, { [styles.active]: isActive });
              }}
              to={navItem.path}
            >
              {navItem.title}
            </NavLink>
          ))}
        </div>
      </div>
      <div className={styles.body}>
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
