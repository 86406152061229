import { languages } from '../../static/languages';

export interface ITranslateResponse {
  translation: string;
}

export type ILangs = GetUnionFromArray<typeof languages>['lang'];

export type ILangsObjs = GetUnionFromArray<typeof languages>;

export type ITranslateDto = {
  text: string;
  to: string;
  from: string;
  work_id?: string;
  session_id?: string;
};

export type ITaskAcceptedDto = {
  task_id: string;
  task_status_url: string;
  task_cancel_url: string;
};

export type ITaskStatusDto = {
  status: StatusType;
  progress: number;
  task_result_url: string;
  task_ttl_seconds: number;
  error_details: IErrorMessageDto;
};

export type IErrorMessageDto = {
  type: string;
  status: number;
  title: string;
  detail: string;
};

export type IValidationResultDto = {
  is_valid: boolean;
};

export enum StatusType {
  NOT_STARTED = 'not_started',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  FAILED = 'failed',
  CANCELED = 'cancelled',
  TIMED_OUT = 'timed_out',
}

export enum OutputFileFormat {
  DOCX = 'docx',
  SRT = 'srt',
  XLSX = 'xlsx',
}
