import React from 'react';
import Text from '../pages/text';
import Voice from '../pages/voice';
import Video from '../pages/video';
import Docs from '../pages/docs';

export const routes = [
  {
    path: '/',
    element: <Voice />,
    title: 'Речи',
  },
  {
    path: '/text',
    element: <Text />,
    title: 'Текста',
  },
  {
    path: '/docs',
    element: <Docs />,
    title: 'Документов',
  },
  {
    path: '/video',
    element: <Video />,
    title: 'Видео',
  },
];
