import axios from 'helpers/axios';
import { api } from '../api';
import { ITranslateDto, ITranslateResponse, ITaskAcceptedDto, ITaskStatusDto, StatusType } from '../dto/translate';

// const TEST_MODE = true;

class TranslateService {
  static handleError(error: Object) {
    if (error?.response?.data) {
      const _data = error.response.data;
      return Promise.reject({
        message: 'Ошибка сервиса: ' + (_data.detail ? _data.detail : error.message),
        err: error,
      });
    } else {
      return Promise.reject({ message: 'Ошибка передачи данных, сервис недоступен ', err: error });
    }
  }

  static translate({ from, to, text, work_id, session_id }: ITranslateDto) {
    const mode = `${from}_${to}`;
    return axios
      .post<ITranslateResponse>(api.translate, { mode, text, session_id, work_id })
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  }

  static speechInput(data: { file: string; mode: string; session_id: string; work_id: string }) {
    return axios
      .post<{ text: string; session_id: string; work_id: string }>(api.speechInput, data)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  }

  static speechOutput(data: { text: string; mode: string }) {
    return axios
      .post<{ file: string }>(api.speechOutput, data)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  }

  static sendFeedback(data: { correct: boolean; session_id: string; work_id: string }) {
    return axios
      .post<{ correct: boolean; sessionId: string; workId: string }>(api.feedback, data, { baseURL: api.endpoint })
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  }

  static sendCorrection(data: { correction: string; session_id: string; work_id: string }) {
    return axios
      .post<{ correct: boolean; sessionId: string; workId: string }>(api.correction, data, { baseURL: api.endpoint })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        throw error;
      });
  }

  static sendDocToTranslate(data: Object, callbackProgressFunction: Function) {
    return axios
      .post<ITaskAcceptedDto>(api.docsTranslateSub, data, {
        baseURL: api.endpoint,
        onUploadProgress: (progressEvent) => {
          if (callbackProgressFunction) callbackProgressFunction(Math.round(progressEvent.progress * 100));
        },
      })
      .then((response) => {
        return response?.data ? response.data : Promise.reject({ message: 'Ошибка сервиса, получены пустые данные' });
      })
      .catch((error) => this.handleError(error));
  }

  static sendVideoToTranslate(data: Object, callbackProgressFunction: Function) {
    return axios
      .post<{ text: string }>(api.videoTranscriptSub, data, {
        baseURL: api.endpoint,
        onUploadProgress: (progressEvent) => {
          if (callbackProgressFunction) callbackProgressFunction(Math.round(progressEvent.progress * 100));
        },
      })
      .then((response) => response)
      .catch((error) => this.handleError(error));
  }

  // static validateVideoLink(data: { video_link: string }) {
  //   return axios
  //     .post<IValidationResultDto>(api.videoValidateLinkSub, data, { baseURL: api.endpoint })
  //     .then((response) => response)
  //     .catch((error) => (TEST_MODE ? Promise.resolve({ is_valid: true }) : this.handleError(error)));
  // }

  static getObjectStatus(linkPart: string) {
    return axios
      .get<ITaskStatusDto>(linkPart, { baseURL: api.endpoint })
      .then((response) => response)
      .catch((error) => this.handleError(error));
  }

  static download(linkPart: string, fileName: string, callbackProgressFunction: Function) {
    return axios
      .get(linkPart, {
        baseURL: api.endpoint,
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          if (callbackProgressFunction) callbackProgressFunction(Math.round(progressEvent.progress * 100));
        },
      })
      .then((response) => {
        const contentDisposition = response.headers['content-disposition'];
        var filename = this.getFileNameOrDefault(contentDisposition, fileName);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const alink = document.createElement('a');
        alink.href = url;
        alink.target = '_blank';
        alink.rel = 'noopener noreferrer';
        alink.setAttribute('download', filename);
        document.body.appendChild(alink);
        alink.click();
      })
      .catch((error) => this.handleError(error));
  }

  static isStatusHealthy(status: ITaskStatusDto): boolean {
    const statusCode = status.status;
    return StatusType.IN_PROGRESS === statusCode || StatusType.COMPLETED === statusCode;
  }

  static getFileNameOrDefault(contentDisposition: string, defaultFileName: string) {
    const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-.]+)(?:; ?|$)/i;
    const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;
    let fileName: string = null;
    if (contentDisposition) {
      if (utf8FilenameRegex.test(contentDisposition)) {
        fileName = decodeURIComponent(utf8FilenameRegex.exec(contentDisposition)[1]);
      } else {
        const filenameStart = contentDisposition.toLowerCase().indexOf('filename=');
        if (filenameStart >= 0) {
          const partialDisposition = contentDisposition.slice(filenameStart);
          const matches = asciiFilenameRegex.exec(partialDisposition);
          if (matches != null && matches[2]) {
            fileName = matches[2];
          }
        }
      }
    }
    return fileName ? fileName : defaultFileName ? defaultFileName : 'translated_file';
  }
}

export default TranslateService;
