import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Layout from './components/layout';

import './styles/global.scss';
import { routes } from './static/navigation';

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <Layout />,

      children: routes,
    },
  ],
  { basename: '/' },
);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
