import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

type IProps = {
  className?: string;
  active?: boolean;
  buttonType?: ButtonType;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export enum ButtonType {
  GRAY = 'gray',
  CLEAR = 'clear',
  PRIMARY = 'primary',
}

const Button: React.FC<IProps> = ({ children, className, buttonType = ButtonType.PRIMARY, ...otherProps }) => {
  return (
    <button className={cn(styles.root, className, styles[buttonType])} {...otherProps}>
      {children}
    </button>
  );
};

export default Button;
