import React, { useCallback, useEffect } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styles from './styles.module.scss';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';

interface IProps {
  id: string;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value?: string;
  readOnly?: boolean;
  maxLen?: number;
  editMode: true;
}

const TextAreaView = observer(({ id, placeholder, onChange, editMode, readOnly, value, maxLen = 10000 }: IProps) => {
  const handleTextKeyPress = useCallback((event: any) => {
    if (event.key === 'Escape') {
      event.target.blur();
    }
  }, []);

  useEffect(() => {
    if (editMode === true) {
      document.getElementById('trasnlationOutput').removeAttribute('readOnly');
    } else if (editMode !== true) {
      document.getElementById('trasnlationOutput').setAttribute('readOnly', 'readOnly');
    }
    if (!value || value.length === 0) {
      (document.getElementById(id) as HTMLTextAreaElement).value = '';
    }
  }, [value, editMode, id]);

  return (
    <div className={styles.textAreaWrap}>
      <TextareaAutosize
        id={id}
        placeholder={placeholder}
        maxLength={maxLen}
        className={cn(styles.textArea, 'scrollbar-fb')}
        value={value}
        onChange={onChange}
        onKeyDownCapture={handleTextKeyPress}
        readOnly={readOnly}
      />
    </div>
  );
});

export default TextAreaView;
