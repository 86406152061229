import axios from 'axios';

const instance = axios.create();

instance.defaults.withCredentials = false;
instance.defaults.maxRedirects = 0;

instance.interceptors.request.use((config) => {
  config.headers.Authorization = '48aea0a7a6634a6f84fe99207c2befcd';
  return config;
});

export default instance;
