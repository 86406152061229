const API_HOST = 'https://tolmach.online';
const API_ENDPOINT = API_HOST + '/api/sber_translator';

export const api = {
  endpoint: API_ENDPOINT,
  translate: `${API_HOST}/api/sber_translator/translate`,
  feedback: `${API_HOST}/api/sber_translator/translate/feedback`,
  correction: `${API_HOST}/api/sber_translator/translate/correction`,
  speechInput: `${API_HOST}/api/sber_translator/speech_to_text`,
  speechOutput: `${API_HOST}/api/sber_translator/text_to_speech`,
  docsTranslateSub: '/docs/translate',
  docsTranslationSub: '/docs/translation/',
  videoTranscriptSub: '/video/transcript',
  videoValidateLinkSub: '/video/validate_link',
};
