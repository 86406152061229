import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import cn from 'classnames';
import { ReactComponent as UploadIcon } from 'icons/ill_upload_doc.svg';
import { ReactComponent as AnimIcon } from 'icons/anim_classic.svg';
import useStores from '../../adapters/store';
import styles from './styles.module.scss';
import Language from 'components/layout/language';

const MAX_FILE_SIZE = 52428800;
const ACCEPTED_MIMETYPES = {
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
  // 'text/plain': ['.txt'],
  // 'text/csv': ['.csv'],
  // 'text/tab-separated-values': ['.tsv', '.tab'],
};

const ACCEPTED_FORMATS_JOINED_LIST = Object.values(ACCEPTED_MIMETYPES).flat().join(', ');

const fileValidator = (file: File) => {
  return file.size <= MAX_FILE_SIZE;
};

function fileSizeValidator(file: File) {
  if (file.size > MAX_FILE_SIZE) {
    return {
      code: 'file-too-large',
      message: 'Size is larger than 50MB',
    };
  }

  return null;
}

const Docs = () => {
  const { docsStore, mainStore } = useStores();
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [fileLoadingMessage, setFileLoadingMessage] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loadingProgress, setLoadingProgress] = useState(0);

  const showError = (error: any) => {
    setError(true);
    setErrorMessage(error);
  };

  const onDropAccepted = useCallback(
    async (files: File[]) => {
      setIsFileLoading(true);
      setFileLoadingMessage('Загружаем...');
      setErrorMessage(null);
      setError(false);

      const hasError = files.reduce((hasError, file) => {
        if (!fileValidator(file)) {
          return true;
        } else {
          return hasError;
        }
      }, false);

      if (hasError) {
        setError(true);
        setIsFileLoading(false);
      } else {
        docsStore
          .translateDocFile({ from: mainStore.fromLang, to: mainStore.toLang, file: files[0] }, setLoadingProgress)
          .then((response) => {
            setFileLoadingMessage('Переводим...');
            setLoadingProgress(0);
            return docsStore.monitorTranslationStatus(response.url, setLoadingProgress).then((downloadUrl) => {
              return { name: response.name, url: downloadUrl };
            });
          })
          .then((response) => {
            setFileLoadingMessage('Выгружаем...');
            setLoadingProgress(0);
            return docsStore.downloadFile(response.url, 'translated_' + response.name, setLoadingProgress);
          })
          .then(() => new Promise((resolve) => setTimeout(resolve, 1000)))
          .catch((err) => {
            console.log(err);
            setLoadingProgress(0);
            showError(err.message);
          })
          .finally(() => {
            setLoadingProgress(0);
            setIsFileLoading(false);
          });
      }
    },
    [docsStore, mainStore.fromLang, mainStore.toLang],
  );

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    accept: ACCEPTED_MIMETYPES,
    onDropAccepted,
    validator: fileSizeValidator,
    multiple: false,
    onDragEnter: () => setError(false),
  });

  return (
    <div>
      <div className={styles.lang}>
        <Language />
      </div>
      <div className={styles.root}>
        {!isFileLoading && (
          <div
            className={cn(styles.dropZone, { [styles.active]: isDragAccept, [styles.error]: isDragReject && !error })}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <UploadIcon className={styles.icon} />
            <p className={styles.text}>
              Перетащите файл в рамку или <span>укажите вручную</span>
            </p>
            <p className={cn(styles.note)}>
              {!isDragReject && !error
                ? `${ACCEPTED_FORMATS_JOINED_LIST} и размером до 50 МБ`
                : `Формат файла не поддерживается. Выберите ${ACCEPTED_FORMATS_JOINED_LIST} размером до 50 МБ.`}
            </p>
            {errorMessage && <p className={styles.err}>{errorMessage}</p>}
          </div>
        )}

        {isFileLoading && (
          <div className={styles.loadingZone}>
            <AnimIcon className={styles.animIcon} />
            <p className={styles.text}>{fileLoadingMessage}</p>
            <p>{loadingProgress}%</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Docs;
