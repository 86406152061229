import cn from 'classnames';
import Language from 'components/layout/language';
import { ReactComponent as CopyIcon } from 'icons/ic_16_layers_copy.svg';
// import { ReactComponent as MicroIcon } from 'icons/ic_16_microphone.svg';
import { ReactComponent as PencilIcon } from 'icons/ic_16_pen_edit.svg';
import { ReactComponent as DisikeIcon } from 'icons/ic_16_thumb_down.svg';
import { ReactComponent as LikeIcon } from 'icons/ic_16_thumb_up.svg';
import { ReactComponent as VolumeIcon } from 'icons/ic_16_volume_down.svg';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useRef, useState } from 'react';
// import { useAudioRecorder } from 'react-audio-voice-recorder';
import { getWaveBlob } from 'webm-to-wav-converter';
import useStores from '../../adapters/store';
import Button, { ButtonType } from '../../components/ui/button';
import TextAreaView from '../../components/ui/textarea';
import styles from './styles.module.scss';

const Text = observer(() => {
  const audioRef = useRef(null);

  const { textStore, mainStore } = useStores();

  // const { startRecording, stopRecording, recordingBlob, isRecording } = useAudioRecorder();

  const [likeActive, setLikeActive] = useState(false);
  const [dislikeActive, setDislikeActive] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [isEdited, setEditedState] = useState(false);
  const [workId, setWorkId] = useState(crypto.randomUUID());

  const handleEdit = useCallback(async () => {
    setEditing(true);
  }, []);

  const getW = useCallback(
    async (recordingBlob: any) => {
      const rec = await getWaveBlob(recordingBlob, false);

      var reader = new FileReader();
      reader.readAsDataURL(rec);
      reader.onloadend = function () {
        const speech = reader.result as string;
        setWorkId(crypto.randomUUID());
        textStore.speechToText({ from: mainStore.fromLang, to: mainStore.toLang, speech: speech, workId: workId });
      };
    },
    [mainStore.fromLang, mainStore.toLang, textStore, workId],
  );

  const cancelCorrection = useCallback(() => {
    setEditing(false);
    textStore.updateTranslation({ val: textStore.backupTranslation });
  }, [textStore]);

  const resetEditControls = useCallback(() => {
    setLikeActive(false);
    setDislikeActive(false);
    setEditing(false);
    textStore.updateTranslation({ val: textStore.backupTranslation });
  }, [textStore]);

  useEffect(() => {
    // resetEditControls();
    // if (!recordingBlob) return;
    // // recordingBlob will be present at this point after 'stopRecording' has been called
    // getW(recordingBlob);
  }, [getW, resetEditControls]);

  // const toggleRecord = useCallback(() => {
  //   if (isRecording) {
  //     stopRecording();
  //   } else {
  //     startRecording();
  //   }
  // }, [isRecording, startRecording, stopRecording]);

  const playVoiceCover = useCallback(async () => {
    const resp = await textStore.textToSpeech({ mode: mainStore.toLang, text: textStore.translation });

    const arrayBuffer = Uint8Array.from(atob(resp.data.file), (c) => c.charCodeAt(0)).buffer;

    const blob = new Blob([arrayBuffer], { type: 'audio/wav' });
    const audioUrl = URL.createObjectURL(blob);

    audioRef.current.src = audioUrl;
    audioRef.current.play();
  }, [mainStore.toLang, textStore]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      textStore.value = event.target.value;
      setWorkId(crypto.randomUUID());

      textStore.translate({ from: mainStore.fromLang, to: mainStore.toLang, text: textStore.value, workId: workId });

      resetEditControls();
    },
    [mainStore.fromLang, mainStore.toLang, resetEditControls, textStore, workId],
  );

  const handleEditing = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      textStore.translation = event.target.value;
      if (textStore.backupTranslation !== textStore.translation) {
        setEditedState(true);
      } else {
        setEditedState(false);
      }
    },
    [textStore],
  );

  const handleFeedback = useCallback(
    async (params: { correct: boolean }) => {
      if (params.correct) {
        setLikeActive(!likeActive);
        setDislikeActive(false);
      } else {
        setLikeActive(false);
        setDislikeActive(!dislikeActive);
      }

      await textStore.sendFeedback({ correct: params.correct, workId: textStore.currentWorkId });
    },
    [textStore, likeActive, dislikeActive],
  );

  const handleCorrection = useCallback(async () => {
    setEditing(false);
    textStore.sendCorrection({ correction: textStore.translation, workId: textStore.currentWorkId });
  }, [textStore]);

  function TranslateWindowButtons() {
    if (!isEditing) {
      return (
        <div className={styles.controls}>
          <Button
            buttonType={ButtonType.CLEAR}
            onClick={() => {
              navigator.clipboard.writeText(textStore.translation);
            }}
          >
            <CopyIcon />
          </Button>

          <Button buttonType={ButtonType.CLEAR} onClick={playVoiceCover}>
            <VolumeIcon />
          </Button>

          <audio hidden ref={audioRef} controls />

          <Button className={styles.editButton} onClick={handleEdit} buttonType={ButtonType.CLEAR}>
            <PencilIcon />
          </Button>

          <Button buttonType={ButtonType.CLEAR} onClick={() => handleFeedback({ correct: true })}>
            <LikeIcon className={cn(styles.feedbackIcons, { [styles.setted]: likeActive })} />
          </Button>

          <Button buttonType={ButtonType.CLEAR} onClick={() => handleFeedback({ correct: false })}>
            <DisikeIcon className={cn(styles.feedbackIcons, { [styles.setted]: dislikeActive })} />
          </Button>
        </div>
      );
    }
    return (
      <div className={styles.feedbackControls}>
        <Button buttonType={ButtonType.CLEAR} className={cn(styles.cancelButton)} onClick={cancelCorrection}>
          <span>Отменить</span>
        </Button>
        <Button
          buttonType={ButtonType.CLEAR}
          disabled={!isEdited}
          className={cn(styles.sendButton, { [styles.enabled]: isEdited })}
          onClick={handleCorrection}
        >
          <span>Отправить</span>
        </Button>
      </div>
    );
  }

  return (
    <div>
      <div className={styles.lang}>
        <Language currentRoute="text" />
      </div>
      <div className={styles.root}>
        <div className={styles.container}>
          <TextAreaView
            id="trasnlationInput"
            maxLen={10000}
            value={textStore.value}
            onChange={handleChange}
            editMode={true}
          />

          <div className={styles.controls}>
            {/* <button className={cn(styles.recordButton, { [styles.active]: isRecording })} onClick={toggleRecord}>
              <span>Остановить</span>
              <MicroIcon className={styles.microIcon} />
            </button> */}
            <span className={styles.counter}>{textStore.value.length} / 10000</span>
          </div>
        </div>

        <div className={styles.container}>
          <TextAreaView
            id="trasnlationOutput"
            value={textStore.translation}
            onChange={handleEditing}
            readOnly
            editMode={isEditing}
          />
          {textStore.translation.length > 0 && <TranslateWindowButtons></TranslateWindowButtons>}
        </div>
      </div>
    </div>
  );
});

export default Text;
