import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import cn from 'classnames';
import { ReactComponent as UploadIcon } from 'icons/ill_upload_video.svg';
import { ReactComponent as AnimIcon } from 'icons/anim_classic.svg';
import { ReactComponent as DocxIcon } from 'icons/docx.svg';
// import { ReactComponent as SrtIcon } from 'icons/srt.svg';
import { ReactComponent as XlsxIcon } from 'icons/xlsx.svg';
import useStores from '../../adapters/store';
import { OutputFileFormat } from '../../adapters/dto/translate';
import styles from './styles.module.scss';
import Button, { ButtonType } from '../../components/ui/button';
import Language from 'components/layout/language';

const MAX_FILE_SIZE = 52428800;
const ACCEPTED_MIMETYPES = {
  'image/jpeg': ['.jpeg', '.jpg'],
  'image/png': ['.png'],
  'video/mp4': ['.mp4'],
};

const fileValidator = (file: File) => {
  return file.size <= MAX_FILE_SIZE;
};

function fileSizeValidator(file: File) {
  if (file.size > MAX_FILE_SIZE) {
    return {
      code: 'file-too-large',
      message: 'Size is larger than 50MB',
    };
  }

  return null;
}

const Video = () => {
  const { docsStore, mainStore } = useStores();
  const [panelState, setPanelState] = useState(0);
  const [selectedObject, setSelectedObject] = useState({});
  const [fileLoadingMessage, setFileLoadingMessage] = useState('');
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const showError = (error: any) => {
    setError(true);
    setErrorMessage(error);
  };
  const clearState = () => {
    setPanelState(0);
    setSelectedObject({});
  };

  // let videoLinkInput = React.createRef();

  const gogogoRangers = (event: Event, _outputFormat: OutputFileFormat) => {
    event.stopPropagation();
    const payload = selectedObject;
    setFileLoadingMessage('Загружаем...');
    const startProcess = payload.linkValue
      ? docsStore.translateVideoLink(
          { from: mainStore.fromLang, to: mainStore.toLang, link: payload.linkValue, outputFormat: _outputFormat },
          setLoadingProgress,
        )
      : payload.fileValue
      ? docsStore.translateVideoFile(
          { from: mainStore.fromLang, to: mainStore.toLang, video: payload.fileValue, outputFormat: _outputFormat },
          setLoadingProgress,
        )
      : null;
    if (null == startProcess) {
      clearState();
    } else {
      setPanelState(2);
      setLoadingProgress(0);
      startProcess
        .then((response) => {
          setFileLoadingMessage('Переводим...');
          setLoadingProgress(0);
          return docsStore.monitorTranslationStatus(response.url, setLoadingProgress).then((downloadUrl) => {
            return { name: response.name, url: downloadUrl };
          });
        })
        .then((response) => {
          setFileLoadingMessage('Выгружаем...');
          setLoadingProgress(0);
          return docsStore.downloadFile(response.url, 'translated_' + response.name, setLoadingProgress);
        })
        .then(() => new Promise((resolve) => setTimeout(resolve, 1000)))
        .catch((err) => {
          console.log(err);
          setLoadingProgress(0);
          showError(err.message);
        })
        .finally(() => {
          setLoadingProgress(0);
          setPanelState(0);
        });
    }
  };

  // const transcriptVideoLink = (event: Event) => {
  //   event.stopPropagation();
  //   const triggerOriginType = event?.target?.tagName;
  //   if ('BUTTON' !== triggerOriginType) return;
  //   setPanelState(1);
  //   setSelectedObject({ linkValue: videoLinkInput?.current?.value });
  // };

  const onDropAccepted = useCallback(async (files: File[]) => {
    setPanelState(1);
    setLoadingProgress(0);
    setFileLoadingMessage('Загружаем...');

    const hasError = files.reduce((hasError, file) => {
      if (!fileValidator(file)) {
        return true;
      } else {
        return hasError;
      }
    }, false);

    if (hasError) {
      setError(true);
      setPanelState(0);
    } else {
      setSelectedObject({ fileValue: files[0] });
    }
  }, []);

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    accept: ACCEPTED_MIMETYPES,
    onDropAccepted,
    validator: fileSizeValidator,
    multiple: false,
    onDragEnter: () => setError(false),
  });

  return (
    <div>
      <div className={styles.lang}>
        <Language />
      </div>
      <div className={styles.root}>
        {!panelState && (
          <div
            className={cn(styles.dropZone, { [styles.active]: isDragAccept, [styles.error]: isDragReject && !error })}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <UploadIcon className={styles.icon} />

            {/* <div className={styles.inputContainer} onClick={(e) => transcriptVideoLink(e)}>
              <p className={styles.text}>Введите ссылку на любое YouTube видео</p>
              <div className={styles.inputWrap}>
                <input placeholder="https://" type="text" className={styles.videoInput} ref={videoLinkInput} />
                <Button>Перевести</Button>
              </div>
            </div> */}
            <p className={styles.text}>
              Перетащите файл в рамку или <span>укажите вручную</span>
            </p>
            <p className={cn(styles.note)}>
              {!isDragReject && !error
                ? 'Ролик должен быть в формате mp4 и не превышать 50 МБ'
                : 'Формат файла не поддерживается. Выберите mp4 и размером до 50 МБ.'}
            </p>
            {errorMessage && <p className={styles.err}>{errorMessage}</p>}
            {/* <Button className={styles.uploadButton}>Загрузить mp4</Button> */}
          </div>
        )}
        {1 === panelState && (
          <div className={styles.loadingZone}>
            <div className={styles.fileTypeButtonGroup}>
              <Button
                buttonType={ButtonType.CLEAR}
                className={styles.fileTypeButton}
                onClick={(e) => gogogoRangers(e, OutputFileFormat.DOCX)}
              >
                <DocxIcon />
              </Button>
              {/* <Button
                buttonType={ButtonType.CLEAR}
                className={styles.fileTypeButton}
                onClick={(e) => gogogoRangers(e, OutputFileFormat.SRT)}
              >
                <SrtIcon />
              </Button> */}
              <Button
                buttonType={ButtonType.CLEAR}
                className={styles.fileTypeButton}
                onClick={(e) => gogogoRangers(e, OutputFileFormat.XLSX)}
              >
                <XlsxIcon />
              </Button>
            </div>
            <p className={styles.text}>Выберите желаемый формат файла с результатом перевода.</p>
          </div>
        )}
        {2 === panelState && (
          <div className={styles.loadingZone}>
            <AnimIcon className={styles.animIcon} />
            <p className={styles.text}>{fileLoadingMessage}</p>
            <p>{loadingProgress}%</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Video;
