import { makeAutoObservable } from 'mobx';
import _debounce from 'lodash.debounce';
import TranslateService from '../service/translateService';

export class TextStore {
  value: string = '';

  translation: string = '';

  backupTranslation: string = '';

  sessionId: string = crypto.randomUUID();

  currentWorkId: string = '';

  constructor() {
    makeAutoObservable(this);
  }

  translate = _debounce(
    async (params: { from: string; to: string; text: string; workId: string; sessionId?: string }) => {
      try {
        this.value = params.text;
        this.currentWorkId = params.workId;

        params.sessionId = this.sessionId;
        const { data } = await TranslateService.translate(params);

        this.translation = data.translation;
        this.backupTranslation = data.translation;
      } catch (e: any) {
        console.log(e);
        this.translation = '';
        this.backupTranslation = '';
      }
    },
    400,
  );

  updateTranslation = (params: { val: string }) => {
    this.translation = params.val;
    this.backupTranslation = params.val;
  };

  switchLang = () => {
    const val = this.value;
    const trans = this.translation;

    this.value = trans;
    this.translation = val;
    this.backupTranslation = val;
  };

  speechToText = async (params: { from: string; to: string; speech: string; workId: string }) => {
    const { data } = await TranslateService.speechInput({
      mode: params.from,
      file: params.speech.replace('data:audio/wave;base64,', ''),
      session_id: this.sessionId,
      work_id: params.workId,
    });

    this.value = data.text;
    this.translate({ from: params.from, to: params.to, text: data.text, workId: params.workId });
  };

  textToSpeech = async (params: { text: string; mode: string }) => {
    console.log('sending data to tts: ', params.text, params.mode);
    const audioBase64 = await TranslateService.speechOutput({
      mode: params.mode,
      text: params.text,
    });

    return audioBase64;
  };

  sendFeedback = async (params: { correct: boolean; workId: string }) => {
    console.log('sending feedback: ', params);
    await TranslateService.sendFeedback({
      correct: params.correct,
      session_id: this.sessionId,
      work_id: params.workId,
    });
  };

  sendCorrection = async (params: { correction: string; workId: string }) => {
    console.log('sending correction: ', params);
    await TranslateService.sendCorrection({
      correction: params.correction,
      session_id: this.sessionId,
      work_id: params.workId,
    });
  };
}
