import React, { useCallback, useMemo, useRef, useState } from 'react';
import { ReactComponent as SwithcLangIcon } from 'icons/ic_16_arrows_left_right.svg';
import Button from 'components/ui/button';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { languages } from 'static/languages';
import useStores from 'adapters/store';
import styles from './styles.module.scss';

interface IProps {
  currentRoute?: string;
  onSwitchLang?: () => void;
  onSelectChange?: () => void;
}

const Language = observer(({ currentRoute, onSwitchLang, onSelectChange }: IProps) => {
  const { mainStore } = useStores();

  const seekWrap = useRef<HTMLDivElement | null>(null);
  const [position, setPosition] = useState<null | number>(null);

  const getSeekPercent = useCallback((event: any) => {
    const seekslider = seekWrap.current;
    if (seekslider) {
      const value = event.clientX - seekslider.getBoundingClientRect().x;
      return value / seekslider.offsetWidth;
    }

    return null;
  }, []);

  const handleMouseEnd = useCallback(() => {
    setPosition(null);
  }, []);

  const handleMouseMove = useCallback(
    (event: any) => {
      setPosition(getSeekPercent(event));
    },
    [getSeekPercent],
  );

  const sourceLangOptions = useMemo(() => {
    const filteredLanguages = languages.filter((lang) => lang.lang !== mainStore.toLangObj.lang);
    return filteredLanguages.map((lang) => (
      <option key={lang.lang} value={lang.lang}>
        {lang.name}
      </option>
    ));
  }, [mainStore.toLangObj.lang]);

  const targetLangOptions = useMemo(() => {
    const filteredLanguages = languages.filter((lang) => lang.lang !== mainStore.fromLangObj.lang);
    return filteredLanguages.map((lang) => (
      <option key={lang.lang} value={lang.lang}>
        {lang.name}
      </option>
    ));
  }, [mainStore.fromLangObj.lang]);

  const handleSelectChange = useCallback(
    (key: 'fromLang' | 'toLang') => (e: any) => {
      mainStore.setLangByKey(key, e.target.value, currentRoute);

      if (key === 'toLang' && onSelectChange) {
        onSelectChange();
      }
    },
    [currentRoute, mainStore, onSelectChange],
  );

  const handleSwitchLang = useCallback(() => {
    mainStore.switchLang();
    if (onSwitchLang) {
      onSwitchLang();
    }
  }, [mainStore, onSwitchLang]);

  return (
    <div className={styles.wrap}>
      <div className={styles.root} onMouseMove={handleMouseMove} onMouseLeave={handleMouseEnd} ref={seekWrap}>
        <div className={cn(styles.langSelect, styles.langSelect1)}>
          {mainStore.fromLangObj.name}
          <select value={mainStore.fromLangObj.lang} name="from" onChange={handleSelectChange('fromLang')}>
            {sourceLangOptions}
          </select>
        </div>
        <Button onClick={handleSwitchLang} className={styles.switch}>
          <SwithcLangIcon />
        </Button>
        <div className={cn(styles.langSelect, styles.langSelect2)}>
          {mainStore.toLangObj.name}
          <select value={mainStore.toLangObj.lang} onChange={handleSelectChange('toLang')} name="from">
            {targetLangOptions}
          </select>
        </div>

        <div
          className={styles.bg}
          style={{
            left: `${(position || 0) * 100}%`,
            opacity: position ? 1 : 0,
            transition: position ? 'opacity .3s linear' : 'none',
          }}
        />
        <div className={styles['bg-colorized']} style={{ opacity: position ? 1 : 0 }} />
      </div>
    </div>
  );
});

export default Language;
