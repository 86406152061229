import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { observer } from 'mobx-react-lite';
import AudioPlayer from 'react-h5-audio-player';
import TextareaAutosize from 'react-textarea-autosize';
import { ReactComponent as TextArrow } from 'icons/normal_u29.svg';
import 'react-h5-audio-player/lib/styles.css';
import cn from 'classnames';

interface IProps {
  audioUrl: string;
  header: string;
  transcritption?: string;
}

const AudioBlobView = observer(({ header, audioUrl, transcritption }: IProps) => {
  const [isTextVisible, setIsTextVisible] = useState(false);

  useEffect(() => {
    // TODO: check if can be overwritten by SASS variables provided by react-h5-audio-player lib
    // https://github.com/lhz516/react-h5-audio-player?tab=readme-ov-file#sass-variables
    var progressIndicator = document.getElementsByClassName('rhap_progress-indicator');
    if (progressIndicator.length > 0) {
      progressIndicator[0].classList.add(styles.controlsBackground);
      progressIndicator[1].classList.add(styles.controlsBackground);
    }

    var progressFilled = document.getElementsByClassName('rhap_progress-filled');
    if (progressFilled.length > 0) {
      progressFilled[0].classList.add(styles.controlsBackground);
      progressFilled[1].classList.add(styles.controlsBackground);
    }

    var playButton = document.getElementsByClassName('rhap_play-pause-button');
    if (playButton.length > 0) {
      playButton[0].classList.add(styles.playButton);
      playButton[1].classList.add(styles.playButton);
    }

    var additionalControls = document.getElementsByClassName('rhap_additional-controls');
    if (additionalControls.length > 0) {
      additionalControls[0].remove();
    }

    var totalTime = document.getElementsByClassName('rhap_time rhap_total-time');
    if (totalTime.length > 0) {
      totalTime[0].remove();
    }

    var volumeControls = document.getElementsByClassName('rhap_volume-controls');
    if (volumeControls.length > 0) {
      volumeControls[0].remove();
    }
  }, []);

  const toggleText = () => {
    setIsTextVisible(!isTextVisible);
  };

  return (
    <div className={styles.textAreaWrap}>
      <AudioPlayer
        src={audioUrl}
        layout="horizontal-reverse"
        showJumpControls={false}
        customVolumeControls={[]}
        customAdditionalControls={[]}
        header={header}
        style={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
        }}
        className={styles.audioElement}
        autoPlay={false}
        autoPlayAfterSrcChange={false}
      />

      <div className={styles.buttonsArea}>
        <button className={styles.showTextButton} onClick={toggleText}>
          <span>Текст</span>
        </button>
        <button className={styles.textArrowButton} onClick={toggleText}>
          <TextArrow className={cn(styles.textArrowIcon, { [styles.enabled]: isTextVisible })} />
        </button>
      </div>

      {isTextVisible && (
        <div className={styles.transcritptionArea}>
          <TextareaAutosize value={transcritption} className={styles.textArea} disabled></TextareaAutosize>
        </div>
      )}
    </div>
  );
});

export default AudioBlobView;
