export const languages = [
  {
    lang: 'ru',
    name: 'Русский',
  },
  {
    lang: 'en',
    name: 'Английский',
  },
  {
    lang: 'zh',
    name: 'Китайский',
  },
  {
    lang: 'uz',
    name: 'Узбекский',
  },
  {
    lang: 'ky',
    name: 'Киргизский',
  },
] as const;

export const ttsLanguages = [
  {
    lang: 'ru',
    name: 'Русский',
  },
  {
    lang: 'en',
    name: 'Английский',
  },
  {
    lang: 'zh',
    name: 'Китайский',
  },
  {
    lang: 'uz',
    name: 'Узбекский',
  },
  {
    lang: 'ky',
    name: 'Киргизский',
  },
] as const;
